import type { Cart, CartItem, FreeDeliveryTerm } from '~/types/Cart';

export interface CartStore {
  cart: Cart;
  mappedCartItems: { id: number; count: number }[];
  isCartLoaded: boolean;
  selectedCartItems: CartItem[];
}

export const useCartStore = createCartStore({
  cart: {
    calculate: {
      discount_data: [],
      discounts: [],
      offer_price_and_discount: [],
      price_without_discounts: 0,
      promo_code: undefined,
      total: 0,
      totalDiscounts: {},
    },
    cart_id: 0,
    items: [],
    restrictions: { required_gism: false },
    status: '',
  },
  isCartLoaded: false,
  mappedCartItems: [],
  selectedCartItems: [],
});

export function createCartStore(initialState: CartStore) {
  return defineStore('cart', {
    state: () => initialState,

    actions: {
      async getCart() {
        try {
          this.isCartLoaded = false;

          const { data } = await useCustomFetch<Cart>('/cart', {}, '2');

          if (data.value) {
            this.cart = data.value;
            this.mappedCartItems = this.cart.items.map((item: CartItem) => ({ id: item.id, count: item.count }));
            this.selectedCartItems = this.cart.items.filter((item: CartItem) => item.is_selected);

            const unavailableItems = this.cart.items.filter((item) => item.available_count === 0);

            if (unavailableItems.length) {
              this.changeSelectedCartItems(
                unavailableItems.map((item) => item.id),
                true,
              );
            }

            this.isCartLoaded = true;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },

      async changeSelectedCartItems(offerIds: number[], isSelected: boolean) {
        this.isCartLoaded = false;

        const data = await useCustomFetchClient(
          '/cart/items',
          {
            body: {
              offer_ids: offerIds,
              action: isSelected ? 'unselect' : 'select',
            },
            method: 'POST',
          },
          '2',
          true,
        );

        if (data) {
          this.cart = data;
          this.mappedCartItems = this.cart.items.map((item: CartItem) => ({ id: item.id, count: item.count }));
          this.selectedCartItems = this.cart.items.filter((item: CartItem) => item.is_selected);
        }

        this.isCartLoaded = true;
      },

      async addToCart(offerId: number) {
        const newCartState = [...this.mappedCartItems, { id: offerId, count: 1 }];

        return await this.changeCartState(newCartState);
      },

      async removeFromCart(offerIds: number[]) {
        if (this.mappedCartItems.length) {
          const filteredItems = this.mappedCartItems.filter((item) => !offerIds.includes(item.id));

          return await this.changeCartState(filteredItems);
        }
      },

      async increment(offerId: number) {
        const item = this.mappedCartItems.find((i) => i.id === offerId);

        if (item) {
          const newCartState = this.mappedCartItems.map((i) => (i.id === offerId ? { ...i, count: i.count + 1 } : i));
          return await this.changeCartState(newCartState);
        }
      },

      async decrement(offerId: number) {
        const item = this.mappedCartItems.find((i) => i.id === offerId);

        if (item) {
          if (item.count - 1 === 0) {
            return await this.removeFromCart([offerId]);
          } else {
            const newCartState = this.mappedCartItems.map((i) => (i.id === offerId ? { ...i, count: i.count - 1 } : i));
            return await this.changeCartState(newCartState);
          }
        }
      },

      async changeCartState(newCartState: { id: number; count: number }[]) {
        const requestId = getRandomString(10);

        const data = await useCustomFetchClient(
          '/cart',
          {
            body: { data: newCartState, requestId },
            method: 'POST',
          },
          '2',
          true,
        );

        if (data) this.mappedCartItems = newCartState;

        return !!data;
      },

      async applyPromoCode(code: string) {
        const data = await useCustomFetchClient<Cart>('/promo/apply', { method: 'POST', body: { code } });

        if (data) this.cart = data;
      },

      async removePromoCode() {
        const data = await useCustomFetchClient<Cart>('/promo/delete', { method: 'DELETE' });

        if (data) this.cart = data;
      },

      async getFreeDeliveryTerms() {
        try {
          const { data } = await useCustomFetch<FreeDeliveryTerm[]>('/free-delivery');

          return data.value ?? [];
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }

          return [];
        }
      },
    },
  });
}
